@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* adding Merriweather font in testimonial section */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --c-bg-primary: rgb(10, 124, 139);
  --c-bg-primaryDark: rgb(3 87 98);

  --c-bg-secondary: hsla(200, 11%, 89%, 0.369);
  --c-secondarylight: #f5f5f5;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

.testimonial {
  font-family: "Merriweather", serif;
}
